.gx-app-login-wrap {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}
@media screen and (max-width: 575px) {
  .gx-app-login-wrap {
    padding-top: 20px;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}

.gx-app-login-wrap .ant-form-item{
  margin-bottom: 20px;

}

.gx-app-login-wrap .ant-form.ant-form-horizontal .ant-form-item .ant-row,
.gx-app-login-wrap .ant-form.ant-form-horizontal .ant-row {

  border: none !important;
}

.gx-app-login-container {
  position: relative;
  width: 490px;
  /* width: 94%; */
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .gx-app-login-container {
    padding-bottom: 20px ;
  }
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
  position: relative;
}
.gx-app-login-content {
  /* padding: 35px 35px 20px;  */
  width: 100%;
}

.gx-app-login-content .title-h4 {
  font-weight: 500;
  font-size: 22px;
  text-align: center;
}
.gx-app-login-content .title-h5 {
  text-align: center;
}

.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #2D3C4D;
}
.gx-app-login-content .gx-btn {
  padding: 6px 35px !important;
  height: auto;
}
.gx-app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-app-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-app-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-logo-content {
  color: #ffffff;
  padding: 35px 35px 20px;
  /* width: 100%; */
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-app-logo-content > * {
  position: relative;
  z-index: 2;
}
.gx-app-logo-content h1 {
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gx-app-logo-content-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  /* background-color: rgba(219, 8, 47, 0.7); */
}
.gx-app-logo-content-bg img {
  width: 100%;
  height: 100%;
}
.gx-app-logo-wid {
  margin-bottom: auto;
}
.gx-app-social-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-app-social-block .gx-social-link,
.gx-app-social-block .gx-social-link li {
  margin: 0;
}
.gx-app-social-block .gx-social-link span {
  border: solid 1px #2D3C4D;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #2D3C4D;
}
.gx-app-social-block .gx-social-link span:hover,
.gx-app-social-block .gx-social-link span:focus {
  color: #ffffff;
  background-color: #2D3C4D;
}
.gx-app-social-block p {
  margin-bottom: 0;
}
.gx-signin-form .ant-form-item-control-input {
  height: 50px;
}

.gx-signin-form .ant-form-item-control-input .ant-form-item-control-input-content {
  height: 100%;
  display: flex;
  align-items: center;
}
.gx-signin-form .ant-form-item-control-input .ant-input {
  height: 100%;
}
.gx-signin-form .ant-form-item.button-center .ant-form-item-control-input-content {
  justify-content: center;
}
.gx-signin-form .ant-form-item.button-space-between .ant-form-item-control-input-content {
  justify-content: space-between;
}
